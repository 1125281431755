
import { useEffect, useRef, useState } from 'react';
import './App.css';
import Footer from './component/Footer';
import Navbar from './component/Navbar';
import Robots from './component/Robots';
import Start from './component/Start';
import { Route, Routes } from 'react-router-dom';
import Challenge from './pages/Challenge';
import LiveAccounts from './pages/LiveAccounts';
import axios from 'axios';
import NotFound from './pages/NotFound';


function App() {
  // const [challenge, setchallenge] = useState({})
  // const getChallenge = () => {
  //   axios("https://tradomationbe.onrender.com/api/v1/acc").then((i) =>
  //     setchallenge(i.data)
  //   )

  // }

  useEffect(() => {
  }, [])
  // const robotRef = useRef()

  // const scrollHandler = () => {
  //   robotRef.current.scrollIntoView({behavior: "smooth"})
  // }
  return (
    <div className="App bg-gray-200">
      <Navbar />
      <Routes>
        <Route path="/" element={[<Start key={0} />, <Robots key={1} />]} />
        {/* <Route
          path="/challenge"
          element={
            <Challenge challenge={challenge} getChallenge={getChallenge} />
          }
        /> */}
        {/* <Route
          path="/live"
          element={
            <LiveAccounts challenge={challenge} getChallenge={getChallenge} />
          }
        /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </div>
  )
}

export default App;
