import React, { useState } from "react"
import data from "../data/robots.json"

function Robots() {
  const [showMagnifier, setShowMagnifier] = useState(false)
  const [[x, y], setXY] = useState([0, 0])
  const [[imgWidth, imgHeight], setSize] = useState([0, 0])
  const magnifierHeight = 150
  const magnifieWidth = 150
  const zoomLevel = 2

  return (
    <div id="robots" className="lg:max-w-6xl mx-auto py-12">
      <h1 className="my-12 text-5xl text-center font-bold text-secondary">
        Our Robots
      </h1>
      {data &&
        data.robots.map((i, a) => (
          <div key={a} className="card bg-base-100 shadow-xl p-1 mb-6">
            <h1 className="text-center text-primary text-4xl my-4">
              #{i.id} | {i.name.toUpperCase()}
            </h1>
            <figure>
              <img
                onMouseEnter={(e) => {
                  // update image size and turn-on magnifier
                  const elem = e.currentTarget
                  const { width, height } = elem.getBoundingClientRect()
                  setSize([width, height])
                  setShowMagnifier(true)
                }}
                onMouseLeave={() => {
                  setShowMagnifier(false)
                }}
                onMouseMove={(e) => {
                  // update cursor position
                  const elem = e.currentTarget
                  const { top, left } = elem.getBoundingClientRect()

                  // calculate cursor position on the image
                  const x = e.pageX - left - window.pageXOffset
                  const y = e.pageY - top - window.pageYOffset
                  setXY([x, y])
                }}
                className="lg:max-w-96 lg:max-h-96"
                src={i.pic}
                alt="Graph"
              />
              <div
                style={{
                  display: showMagnifier ? "" : "none",
                  position: "absolute",

                  // prevent magnifier blocks the mousemove event of img
                  pointerEvents: "none",
                  // set size of magnifier
                  height: `${magnifierHeight}px`,
                  width: `${magnifieWidth}px`,
                  // move element center to cursor pos
                  top: `${y - magnifierHeight / 2}px`,
                  left: `${x - magnifieWidth / 2}px`,
                  opacity: "1", // reduce opacity so you can verify position
                  border: "1px solid lightgray",
                  backgroundColor: "white",
                  backgroundImage: `url('${i.pic}')`,
                  backgroundRepeat: "no-repeat",

                  //calculate zoomed image size
                  backgroundSize: `${imgWidth * zoomLevel}px ${
                    imgHeight * zoomLevel
                  }px`,

                  //calculate position of zoomed image.
                  backgroundPositionX: `${
                    -x * zoomLevel + magnifieWidth / 2
                  }px`,
                  backgroundPositionY: `${
                    -y * zoomLevel + magnifierHeight / 2
                  }px`,
                }}
              ></div>
            </figure>
            <div className="card-body">
              {/* <h2 className="card-title ">{i.name}</h2> */}
              <div className="stats stats-vertical lg:stats-horizontal shadow">
                <div className="stat">
                  <div className="stat-figure  text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="inline-block w-8 h-8 stroke-current"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <div className="stat-title text-secondary">Symbol</div>
                  <div className="stat-value text-primary">
                    {i.symbol.toUpperCase()}
                  </div>
                  <div className="stat-desc text-accent">
                    Adjustable for other symbols
                  </div>
                </div>

                <div className="stat">
                  <div className="stat-figure  text-primary">
                    <svg
                      className="inline-block w-8 h-8 stroke-current"
                      viewBox="0 0 20 20"
                    >
                      <path d="M17.431,2.156h-3.715c-0.228,0-0.413,0.186-0.413,0.413v6.973h-2.89V6.687c0-0.229-0.186-0.413-0.413-0.413H6.285c-0.228,0-0.413,0.184-0.413,0.413v6.388H2.569c-0.227,0-0.413,0.187-0.413,0.413v3.942c0,0.228,0.186,0.413,0.413,0.413h14.862c0.228,0,0.413-0.186,0.413-0.413V2.569C17.844,2.342,17.658,2.156,17.431,2.156 M5.872,17.019h-2.89v-3.117h2.89V17.019zM9.587,17.019h-2.89V7.1h2.89V17.019z M13.303,17.019h-2.89v-6.651h2.89V17.019z M17.019,17.019h-2.891V2.982h2.891V17.019z"></path>
                    </svg>
                  </div>
                  <div className="stat-title text-secondary">
                    MonthyProfit "avg"
                  </div>
                  <div className="stat-value text-primary">{i.profit}</div>
                  <div className="stat-desc text-accent">
                    Number of Indicators ({i.indicatorsAmount})
                  </div>
                </div>

                <div className="stat">
                  <div className="stat-figure  text-primary">
                    <svg
                      className="inline-block w-8 h-8 stroke-current"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10.281,1.781C5.75,1.781,2.062,5.469,2.062,10s3.688,8.219,8.219,8.219S18.5,14.531,18.5,10S14.812,1.781,10.281,1.781M10.714,2.659c3.712,0.216,6.691,3.197,6.907,6.908h-6.907V2.659z M10.281,17.354c-4.055,0-7.354-3.298-7.354-7.354c0-3.911,3.067-7.116,6.921-7.341V10c0,0.115,0.045,0.225,0.127,0.305l5.186,5.189C13.863,16.648,12.154,17.354,10.281,17.354M15.775,14.882l-4.449-4.449h6.295C17.522,12.135,16.842,13.684,15.775,14.882"></path>
                    </svg>
                  </div>

                  <div className="stat-title text-secondary">
                    Backtest Result
                  </div>
                  <div className="stat-value text-primary">{i.testProfit}</div>
                  <div className="stat-desc text-accent">
                    1.Jan.22 - 30.Jul.23
                  </div>
                </div>
              </div>
              {i.roi && (
                <div className="overflow-x-auto">
                  <h1 className="text-center text-3xl mt-12 mb-6  text-primary">
                    Table of ROI
                  </h1>
                  <table className="table">
                    {/* head */}
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-secondary">Period</th>
                        <th className="text-secondary">Trades</th>
                        <th className="text-secondary">Max Deposit DD</th>
                        <th className="text-secondary">Max Equity DD</th>
                        <th className="text-secondary">Net Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* row 1 */}
                      {i.roi.map((j, k) => (
                        <tr key={k}>
                          <th className="text-secondary">{k + 1}</th>
                          <td>{j.date}</td>
                          <td>{j.trades}</td>
                          <td>{j.depDD}</td>
                          <td>{j.balanceDD}</td>
                          <td className="text-accent">{j.profit}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {i.video && (
                <div className="mx-auto lg:w-full">
                  <iframe
                    width="100%"
                    className="h-96 lg:h-[40rem] "
                    src={i.video}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              {i.link && (
                <div className="card-actions justify-end">
                  <a href={i.link} target="_blank" className="btn btn-primary">
                    Result Details
                  </a>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  )
}

export default Robots
