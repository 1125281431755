import React, { useRef } from 'react'

function Start() {
  const robotRef = useRef()

  const scrollHandler = () => {
    robotRef.current.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <div
      className="hero min-h-screen"
      style={{
        backgroundImage:
          "url(https://images.unsplash.com/photo-1642790551116-18e150f248e3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1933&q=80)",
      }}
    >
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-md">
          <h1 className="mb-5 text-4xl font-bold">TRADOMATION</h1>
          <h1 className="mb-5 text-2xl font-bold">Algo-trading</h1>
          <p className="mb-5">
            Our advanced trading-bots, trained on decades of market data, works
            tirelessly 24/7, spotting trends and making data-driven decisions
            faster than any human could. It scans the movements of the market in
            real time, capitalizing on opportunities and protecting your
            investments from potential threats.
          </p>
          <p className="mb-5 text-2xl font-bold mt-4">
            SMARTER TRADES
            <br /> &
            <br /> HIGHER GAINS
          </p>
          <a onClick={scrollHandler} className="btn btn-primary">Get Started</a>
        </div>
      </div>
      <p ref={robotRef} className="absolute bottom-[-100px] invisible"></p>
    </div>
  )
}

export default Start