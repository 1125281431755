import React from 'react'

function NotFound() {
  return (
    <div className="h-screen w-full bg-base-100 flex justify-center items-center">
      <img
        src="https://ik.imagekit.io/kaa/tradomation%20not%20found_V7vyq4vu3.png?updatedAt=1695101605092"
        alt=""
      />
    </div>
  )
}

export default NotFound