import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
    return (
      <div className="bg-primary text-primary-content">
        <div className="navbar  lg:max-w-6xl lg:mx-auto">
          <div className="navbar-start">
            <div className="dropdown">
              <label tabIndex={0} className="btn btn-ghost lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </label>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 text-primary"
              >
                <li>
                  <Link to="/">
                    Home
                  </Link>
                </li>
                {/* <li>
                  <Link to="/challenge">
                   Live-Challenge
                  </Link>
                </li> */}
                {/* <li>
                  <Link to={'/live'} >

                  Live Accounts
                  </Link>
                </li> */}

                
              </ul>
            </div>
            <Link to={'/'} className="btn btn-ghost normal-case text-xl">
              <img
                src="https://ik.imagekit.io/kaa/robots/tradomation%20logo%20small_0tF3_d3ZdZ.png?updatedAt=1691054325712"
                alt=""
                className="h-full"
              />
            </Link>
          </div>
          <div className="navbar-end hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li>
                <Link to="/">
                  Home
                </Link>
              </li>
              {/* <li>
                <Link to="/challenge">
                  Live-Challenge
                </Link>
              </li> */}
              {/* <li>
                <Link to={'/live'}>

                Live Accounts
                </Link>
              </li> */}
            </ul>
          </div>
          
        </div>
      </div>
    )
}

export default Navbar